import { getTimelessStringFromDateLocal } from '@grantstreet/psc-js/utils/date.js'
import { parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import { sentryException } from '../sentry.js'
import { frequencies, specs } from '../models/Frequencies.js'
import { anyKey, anyValue } from '@grantstreet/psc-js/utils/objects.js'

export default class Schedule {
  constructor ({
    payable,
    frequency,
    amount = {
      value: '',
      spec: '',
    },
    tender,
    user,
    active = false,
    adminOnly = false,
    id = '',
    pause = {
      startDate: null,
      endDate: null,
    },
    agreement = {
      terms: '',
      time: '',
    },
    client = '',
    site = '',
    requiresTenderChange = false,
    createdAt = '',
    modifiedAt = '',
    modifiedBy = '',
  }) {
    this.payable = payable
    this.tender = tender
    // user will look something like the following
    // (note the use of "sub" instead of the backend "id")
    //  {
    //    email: user.email,
    //    sub: user.id,
    //    phone: user.phone,
    //    name: user.name,
    //    language: user.language || 'en',
    //  }
    this.user = user
    this.amount = { ...amount }
    this.active = active
    this.adminOnly = adminOnly
    this.id = id
    this.pause = pause
    this.agreement = agreement
    this.client = client
    this.site = site
    this.requiresTenderChange = requiresTenderChange

    this.frequency = frequency ? frequency.clone() : Schedule.createFrequency({ isNull: true, payable })
    const { startDate } = this.frequency.spec
    this.frequency.spec.startDate = startDate ? new Date(startDate) : new Date()

    this.createdAt = createdAt
    this.modifiedAt = modifiedAt
    this.modifiedBy = modifiedBy
  }

  /*****************************************************************************
   * SCHEDULE FUNCTIONS
   *
   * These are primarily used in MyPayments and ScheduledPaymentCard to observe information
   * About the schedule and its payable in a user-friendly format
   */

  get payableDisplaytype () {
    return this.payable.configDisplayType.icon
  }

  get paymentAmount () {
    return Schedule.getPaymentAmount(this)
  }

  // this could be used for non-admins if we translated the result
  get lastModifiedUserForAdmin () {
    const modifiedBy = this.modifiedBy

    if (modifiedBy === '') {
      return '—'
    }
    if (modifiedBy === this.user.sub) {
      return this.user.name
    }

    // If there's no colon, this is probably just a generic Okta subject
    // And displaying it to an admin user would not provide helpful information
    if (!modifiedBy.includes(':')) {
      return 'Admin'
    }

    return 'Admin - ' + modifiedBy.match(/:(.+)$/)[1]
  }

  static getPaymentAmount ({ amount: { spec, value } = {}, payable: { amount } = {} }) {
    if (spec === 'otherAmount') {
      return parseNumber(value)
    }
    if (amount < 0) {
      return 0
    }
    return amount
  }

  static formatApiDate (inputdate) {
    let date
    if (typeof inputdate === 'string') {
      date = new Date(inputdate)
    }
    else {
      date = inputdate
    }

    if (isNaN(date)) {
      sentryException(new Error(`Cannot format improper date ${inputdate}`))
      return ''
    }
    return getTimelessStringFromDateLocal(date)
  }

  static createSpec ({ payable, specKey, startDate = null, daysBeforeDue, interval, dayOfWeek } = {}) {
    if (!daysBeforeDue) {
      const { maxDaysBeforeDue: max, minDaysBeforeDue: min } = payable.scheduledPaymentsConfig
      // Keep this default in sync with AutopayForm.vue
      daysBeforeDue = Math.max(Math.min(4, max), min)
    }
    if (!specKey) {
      // We never rely on key order being critical (because it's not reliable)
      // so use a random value
      specKey = Object.keys(anyValue(payable.allowedSchepTypes))[0]
    }
    return new specs[specKey]({ startDate, daysBeforeDue, interval, dayOfWeek })
  }

  static createFrequency (args = {}) {
    if (!args.frequencyKey) {
      // We never rely on key order being critical (because it's not reliable)
      // so use a random key
      args.frequencyKey = anyKey(args.payable.allowedSchepTypes)
    }
    return new frequencies[args.frequencyKey]({
      ...args,
      spec: Schedule.createSpec(args),
    })
  }
}
