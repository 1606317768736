import Vue from 'vue'
import decode from 'jwt-decode'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { formatDate } from '@grantstreet/psc-js/utils/date.js'
import { orderPayablesByPaths, searchPayables, searchPayablesPath, searchPayablesPaths } from '@grantstreet/payables'
import { sentryException } from '../sentry.js'
import { parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import { configState, configGetters, loadConfig } from '@grantstreet/psc-config'
import Schedule from '../models/Schedule.js'
import { CreateAutopayFrequencyFromPayableForCheckout, CreateFrequencyRule } from '../models/Frequencies.js'

const isFutureRun = run => run.status === 'future' || run.status === 'future_suspended'

export default ({ user }) => {
  const getters = {
    // Initialization promise
    loadPromise: state => {
      if (state.loadPromise) {
        return state.loadPromise
      }
      throw new Error('SchedPay/loadPromise is not initialized')
    },

    schedules: state => state.schedules,
    isScheduled: state => payable => Boolean(state.schedules[payable.raw.save_path]),
    getSchedule: state => payable => state.schedules[payable.raw.path],
    autoloadPath: state => state.autoloadPath,
    searchBy: state => state.searchBy,
    clientSearch: state => state.clientSearch,
    siteSearch: state => state.siteSearch,
    savedSearchInputs: stat => stat.savedSearchInputs,
    showTermsCheckbox: state => state.showTermsCheckbox,
    payableSearches: (...[, , , rootGetters]) => rootGetters['PayHub/searchPages'],
    doneLoadingAdmin: (state) => state.doneLoading,

    adminCanEdit: () => configState.adminScope?.includes('admin:schedpay:write'),
    adminCanCreate: (...[, getters]) => getters.adminCanEdit || configState.adminScope?.includes('admin:schedpay:create'),

    adminShowDropdowns: state => state.showDropdowns,

    adminHasAccessToSchedule: (state, getters, rootState, rootGetters) => (schedule) => {
      return configGetters.adminHasAccessToSite(schedule.client, schedule.site)
    },

    clientAdminOptions: (state, getters, rootState, rootGetters) => {
      const accessClients = [...new Set(configState.clientSiteAccessList.map(({ client }) => client))]
      const options = [
        {
          text: 'Select Client',
          value: null,
        },
      ]
      for (const clientKey of accessClients) {
        // We don't add clients that don't have any usable sites
        if (getters.siteAdminOptions(clientKey).length > 1) {
          options.push({
            text: configGetters.friendlyClientName(clientKey),
            value: clientKey,
          })
        }
      }
      return options
    },

    siteAdminOptions: (state, getters, rootState) => (forClient) => {
      if (!forClient) forClient = state.clientSearch

      const accessSites = configState.clientSiteAccessList.filter(({ client }) => client === forClient)
      const clientMeta = configState.clientList.find(client => client.id === forClient) || {}
      const options = [
        {
          text: 'Select Site',
          value: null,
        },
      ]
      for (let siteKey of accessSites) {
        siteKey = siteKey.site
        const site = configState.allConfigs[forClient][siteKey]
        if (site.schedPay && site.schedPay.meta && site.schedPay.meta.enabled) {
          options.push({
            text: site.meta.siteName,
            value: {
              ...site,
              logoUrl: clientMeta.logoUrl,
              useClientOnlyUrl: clientMeta.useClientOnlyUrl,
              defaultSiteUrl: clientMeta.defaultSiteUrl,
            },
          })
        }
      }
      return options
    },

    getNextPaymentDateSummary: state => (runs, numeric = false) => {
      if (!runs.length) {
        return i18n.global.t('schedpay.schedule_form.summary.no_run')
      }
      const date = runs[0].date !== null
        ? formatDate(new Date(runs[0].date), numeric, i18n.global.locale.value)
        : i18n.global.t('schedpay.schedule_form.summary.tbd')
      return date
    },

    // Need to look at the JWT to tell whether this is prod or not
    isProd: (state, getters, rootState, rootGetters) => {
      let jwt
      try {
        jwt = decode(user.getAccessToken())
      }
      catch (error) {
        sentryException('Error decoding jwt')
      }
      const iss = jwt?.iss || ''
      return iss.startsWith('https://grantstreet.okta.com/')
    },

    originUrl: (state, getters) => {
      return getters.isProd ? 'https://govhub.com' : 'https://beta.govhub.com'
    },
    clientSite: (state, getters, rootState) => `${configState.config.client}/${configState.config.site}`,
    postVerifyUrl: (state, getters, rootState) => {
      const { client, site, useClientOnlyUrl, defaultSiteUrl } = configState.config
      const origin = getters.originUrl
      const singleSite = useClientOnlyUrl ||
      (defaultSiteUrl &&
        defaultSiteUrl === site)

      return `${origin}/${client}${singleSite ? '' : `/${site}`}`
    },

    userDisabledFrequencies: (state, getters, rootState) => {
      return configState.config.schedPay.userDisabledFrequencies
    },

    /**
     * restrictedPaymentForms returns the payment forms that should be disabled
     * for this schedule based on the site's allowed tender types and the
     * payable's allowed tender types, and (optionally) the projected amount of
     * the scheduled payment (compared against any tender type payment limits
     * configured).
     */
    restrictedPaymentForms: (state, getters, rootState, rootGetters) => (schedule, compareAmount = false) => {
      const paymentLimit = rootGetters['PayHub/getPaymentLimits'](schedule.payable?.departmentCode)
      if (!paymentLimit) {
        return []
      }
      const { cardLimit, bankLimit } = paymentLimit
      const allowed = getters.tenderTypesEnabledForPayable(schedule.payable)
      const scheduleAmount = schedule.amount.spec === 'amountDue' ? schedule.payable.amount : schedule.amount.value
      const cardLimitAmount = parseNumber(cardLimit || 0)
      const bankLimitAmount = parseNumber(bankLimit || 0)

      const paymentForms = []
      if (
        cardLimitAmount > 0 &&
      allowed.includes('card') &&
      (!compareAmount || scheduleAmount > cardLimitAmount) &&
      rootGetters['Cart/cardsSiteEnabled']
      ) {
        paymentForms.push('card')
      }
      // XXX: PayPal, Apple Pay, and Google Pay are not configured for Schep, so
      // we should not add them to the list of restrictions yet. Otherwise, they
      // will confusingly show up in messages describing the payment limits, but
      // still won't exist as options in the TenderManager.
      // if (rootGetters['Cart/payPalSiteEnabled']) {
      //   paymentForms.push('paypal')
      // }
      // if (rootGetters['Cart/allowedAlternativeTenderSources']?.length) {
      //   paymentForms.push(...rootGetters['Cart/allowedAlternativeTenderSources'])
      // }

      if (bankLimitAmount > 0 && allowed.includes('bank') && rootGetters['Cart/banksSiteEnabled'] && (!compareAmount || scheduleAmount > bankLimitAmount)) {
        paymentForms.push('bank')
      }

      return paymentForms
    },

    /**
     * tenderTypesEnabledForPayable returns an array of payment forms
     * that are allowed by the given payable and enabled for the current
     * site.
     */
    tenderTypesEnabledForPayable: (state, getters, rootState) => (payable) => {
      if (!payable) {
        return []
      }

      return configState.config.eWallet.allowedMethods.filter(method => payable.allowedTenderTypes.includes(method))
    },

    /**
     * tenderTypesDisabledForPayable returns an array of payment forms
     * that should not be selected by the user when scheduling a plan for
     * this payable.
     */
    tenderTypesDisabledForPayable: (state, getters, rootState) => (payable) => {
      const siteAllowedMethods = configState.config.eWallet.allowedMethods
      if (!payable) {
      // If we have no payable, we cannot allow any types
        return siteAllowedMethods
      }
      return siteAllowedMethods.filter(method => !(payable.allowedTenderTypes.includes(method)))
    },

    /**
     * paymentLimits returns an array of objects describing payment limits that
     * apply to this schedule. Each item in the array has the "limit" amount
     * and which "payment methods" it applies to. The array is sorted by limit
     * amount, lowest amount first.
     */
    paymentLimits: (state, getters, rootState, rootGetters) => (schedule, compareAmount = false) => {
      const paymentLimit = rootGetters['PayHub/getPaymentLimits'](schedule.payable?.departmentCode)
      if (!paymentLimit) {
        return []
      }
      const { cardLimit, bankLimit } = paymentLimit
      const cardLimitAmount = parseNumber(cardLimit || 0)
      const bankLimitAmount = parseNumber(bankLimit || 0)
      if (!cardLimitAmount && !bankLimitAmount) {
        return []
      }

      const restrictedForms = getters.restrictedPaymentForms(schedule, compareAmount)
      if (!restrictedForms.length) {
        return []
      }

      if (!cardLimitAmount || !bankLimitAmount || cardLimitAmount === bankLimitAmount) {
        return [
          {
            paymentForms: restrictedForms,
            amount: cardLimitAmount > 0 ? cardLimit : bankLimit,
          },
        ]
      }
      else {
        const cardLimitObject = [
          {
            paymentForms: restrictedForms.filter(t => t !== 'bank'),
            amount: cardLimit,
          },
        ]
        const bankLimitObject = [
          {
            paymentForms: ['bank'],
            amount: bankLimit,
          },
        ]
        // Lower amount first
        return cardLimitAmount < bankLimitAmount ? [cardLimitObject, bankLimitObject] : [bankLimitObject, cardLimitObject]
      }
    },

    getPendingAddressChange: (state) => (payablePath) => {
      return state.pendingAddressChanges[payablePath]
    },
  }

  const actions = {
    getRuns ({ rootGetters }, { schedule, future = false }) {
      const api = rootGetters['API/schedPay']
      return api.getRuns(schedule.id, future)
    },

    getPastRuns ({ rootGetters }, { schedule }) {
      const api = rootGetters['API/schedPay']
      return api.getRuns(schedule.id, false)
    },

    async getFutureRuns ({ rootGetters }, { schedule }) {
      const api = rootGetters['API/schedPay']
      return (await api.getRuns(schedule.id, true)).filter(isFutureRun)
    },

    async getPastAndFutureRuns ({ rootGetters }, { schedule }) {
      const api = rootGetters['API/schedPay']
      const allRuns = await api.getRuns(schedule.id, true)
      const pastRuns = []
      const futureRuns = []
      for (let i = 0; i < allRuns.length; i++) {
        const thisRun = allRuns[i]
        if (isFutureRun(thisRun)) {
          futureRuns.push(thisRun)
        }
        else {
          pastRuns.push(thisRun)
        }
      }

      return { pastRuns, projectedRuns: futureRuns }
    },

    async getRunsByStatus ({ rootGetters }, { schedule, status, future = false }) {
      const api = rootGetters['API/schedPay']
      const runs = await api.getRuns(schedule.id, future)
      return runs.filter(run => run.status === status)
    },

    // record the user who performed the action
    async adminAddUpdateSchedule ({ dispatch }, { schedule }) {
      return dispatch('addUpdateSchedule', {
        schedule,
        'userJwt': configState.adminUserJwt,
      })
    },

    /**
     * Creates or updates a scheduled payment plan, whichever is appropriate.
     * Does not record a user unless one is provided.
     *
     * @param {Object} obj A parameters object
     * @return {String} 'success' or an error code
     */
    async addUpdateSchedule ({ rootGetters, commit }, {
      schedule,
      userJwt,
    }) {
      const api = rootGetters['API/schedPay']
      try {
        // Only pre-existing schedules have an id that they received from the
        // back end
        if (schedule.id) {
          await api.updateSchedule(schedule, userJwt)
        }
        else {
          await api.addSchedule(schedule, userJwt)
        }
        commit('setSchedule', schedule)

        return 'success'
      }
      catch (error) {
        console.error('Could not complete schedule update')
        console.error(error)
        return error.response?.data?.errorCode || 'error'
      }
    },

    // TODO turn on hook in @grantstreet/login-vue's index file
    // @grantstreet/login-vue is gone as of PSC-15659. Above comment now
    // obsolete?
    async retrieveUserSchedules ({ state, rootGetters, dispatch, commit }, { inactive = false } = {}) {
      const schedules = await dispatch('searchSchedules', {
        by: 'username',
        inactive,
      })

      for (const schedule of schedules) {
        commit('setSchedule', schedule)
      }
    },

    lookupUsers ({ rootGetters }, { email }) {
      return rootGetters['API/login'].findUsersByEmail(email)
    },

    createUser ({ rootGetters, getters }, { email, name, phone, forceActive, callback }) {
      return rootGetters['API/login'].createUser({
        email,
        name,
        phone,
        options: {
          'client_site': getters.clientSite,
          'origin_url': getters.postVerifyUrl,
          'email_data': {
            // We use "en" here because the Schep Admin widget does not know
            // what the language will be for the user being created.
            templateName: 'govhub/schep/login_activation/en',
            templateData: {
              email,
              'client_site': getters.clientSite,
            },
            subject: i18n.global.t('schedpay.create_profile_subject'),
          },
        },
        forceActive,
      }).then(({ data }) => {
        callback(data)
      }).catch((error) => {
        console.error(error)
        callback()
      })
    },

    async adminRemoveSchedule ({ state, dispatch, rootState }, { schedule }) {
      return dispatch('removeSchedule', {
        schedule,
        'user': configState.adminUserJwt,
      })
    },

    async removeSchedule ({ state, rootGetters }, { schedule, user, preserveLocal = false }) {
      const api = rootGetters['API/schedPay']
      try {
        await api.deleteSchedule(schedule, user)
        if (!preserveLocal) {
          Vue.delete(state.schedules, schedule.payable.path)
        }
        schedule.active = false
        return true
      }
      catch (error) {
        console.error('Could not complete schedule removal')
        console.error(error)
        return false
      }
    },

    // Searches for scheduled payment plans by a given search parameter.
    //
    // This does not return schedules that have no corresponding payable (see
    // ../api-client.js@getSchedulesBy).
    // TODO: Test schep admin
    async searchSchedules ({ state, dispatch, rootState, commit, rootGetters }, {
      data,
      by = state.searchBy.scopeName,
      inactive = false,
    }) {
      if (['email', 'username', 'tender', 'nextPayment'].includes(by)) {
        return rootGetters['API/schedPay'].getSchedulesBy(
          {
            by,
            data,
            inactive,
            client: configState.config.client,
            site: configState.config.site,
          },
          // Payable getter function (this call is explicitly NOT awaited)
          payablePaths => searchPayablesPaths({
            paths: payablePaths,
            language: i18n.global.locale.value,
          }),
        )
      }
      else if (by === 'payablesPaths') {
        return rootGetters['API/schedPay'].searchSchedules({
          by,
          data,
          inactive,
          client: configState.config.client,
          site: configState.config.site,
        })
      }

      let payables
      if (by === 'path') {
      // update scope name so drop down menus are consistent between 'NewPlan'
      // and 'SchepSearchBox'
        state.searchBy.scopeName = 'payableSearch'
        commit('setSearchBy', state.searchBy)
        const params = {
          path: data,
          language: i18n.global.locale.value,
        }
        payables = [await searchPayablesPath(params)]
      }
      else {
        payables = (await searchPayables({
          payablesAdaptor: data.payablesAdaptor,
          data: data.data,
          language: i18n.global.locale.value,
        })).payables
      }

      return dispatch('payablesHaveSchedules', {
        payables,
        inactive,
      })
    },

    async payablesHaveSchedules ({ dispatch, rootGetters, rootState }, { payables, inactive = false }) {
      if (!payables.length) {
        return []
      }

      const api = rootGetters['API/schedPay']
      const schedules = await api.getSchedulesBy(
        {
          by: 'path',
          data: payables.map((payable) => payable.raw.path),
          inactive,
          client: configState.config.client,
          site: configState.config.site,
        },
        // Payable getter function
        // async to match other usage
        async payablePaths => orderPayablesByPaths({ payables, orderedPaths: payablePaths }),
      )
      return schedules.flat()
    },

    getScheduleDetails ({ rootGetters }, { id }) {
      return rootGetters['API/schedPay'].getScheduleDetails(id)
    },

    async setScheduleRuns ({ dispatch, getters }, { schedule }) {
      try {
        const { pastRuns, projectedRuns } = await dispatch('getPastAndFutureRuns', { schedule })
        const nextRun = projectedRuns.length ? projectedRuns[0] : null
        if (nextRun && nextRun.status === 'future_suspended') {
          Vue.set(schedule, 'nextRun', i18n.global.t('schedpay.runs.paused'))
        }
        else {
          Vue.set(schedule, 'nextRun', getters.getNextPaymentDateSummary(projectedRuns))
        }

        Vue.set(schedule, 'previousRuns', pastRuns || [])
      }
      catch (error) {
        console.error('Error retrieving runs for schedule id: ' + schedule.id)
        console.error(error)
        Vue.set(schedule, 'runsError', true)
      }
    },

    /**
     * This is dispatched then the user selects a site for searching plans or
     * payables. Since this makes an HTTP request to load the site's config, it
     * should be awaited.
     */
    async setSiteSearch ({ state }, value) {
    // TODO: Stop using Vue.set
      Vue.set(state, 'searchBy', null)
      Vue.set(state, 'siteSearch', value)
      if (value) {
        return loadConfig({
          client: state.clientSearch,
          site: value.site,
        })
      }
    },

    adminCheckDropdowns ({ state, dispatch, getters, commit }) {
      const clients = getters.clientAdminOptions
      // we only have 1 client with sites we can use
      if (clients.length === 2) {
        commit('setClientSearch', clients[1].value)
        const sites = getters.siteAdminOptions(clients[1].value)
        // there's only 1 site we can use
        if (sites.length === 2) {
          Vue.set(state, 'showDropdowns', false)
          dispatch('setSiteSearch', sites[1].value)
        }
      }
    },

    // runSchedule runs a schedule and returns the run's details
    runSchedule ({ rootGetters }, { schedule }) {
      const api = rootGetters['API/schedPay']
      return api.runSchedule(schedule, configState.adminUserJwt)
    },

    async createCheckOutSchedules ({ dispatch }, { items, tender, extraFields, user, language }) {
      let aggregateSuccess = true

      const promises = items
        .filter(({ payable, enrollInAutopay, scheduledPaymentAgreement }) => enrollInAutopay && scheduledPaymentAgreement)
        .reduce((promises, { payable, enrollInAutopay, scheduledPaymentAgreement }) => {
          if (!enrollInAutopay || !scheduledPaymentAgreement) {
            return promises
          }

          // Create schedule
          promises.push((async () => {
            const schedule = new Schedule({
              payable,
              tender,
              client: configState.config.client,
              site: configState.config.site,
              active: true,
              ...CreateAutopayFrequencyFromPayableForCheckout(payable),
              user: {
                sub: user.id,
                name: user.name,
                email: extraFields?.email || user.email,
                phone: user.phone,
                language,
              },
              agreement: scheduledPaymentAgreement,
            })

            const status = await dispatch('addUpdateSchedule', { schedule })

            // If any promise rejects Promise.all will quit immediately and
            // return only that value. We want all requests to be attempted and
            // all displayNames to be returned for messages
            const success = status === 'success'
            if (!success) {
              aggregateSuccess = false
            }

            return {
              success,
              displayName: payable.displayName,
            }
          })())

          return promises
        }, [])

      // Await separately from the return to ensure that success is set before
      // the object is created. (Not positive it is a problem)
      const requests = await Promise.all(promises)

      if (aggregateSuccess) {
        return []
      }

      return requests.map(({ success, displayName }) => ({
        success,
        displayName,
      }))
    },

    async getCheckOutScheduleErrors ({ dispatch }, { items }) {
      const savePaths = items.map(item => item?.savePath || item?.raw?.save_path || item?.details?.save_path)
      const { data: schedules } = await dispatch('searchSchedules', {
        by: 'payablesPaths',
        data: savePaths,
        inactive: false,
      })

      if (schedules.length === savePaths.length) {
        return []
      }

      const schedulePaths = schedules.map(plan => plan.payablePath)

      return items.map(item => {
        const displayName = item?.displayName || item?.raw?.display_name || item?.details?.display_name
        const savePath = item?.savePath || item?.raw?.save_path || item?.details?.save_path

        return {
          success: schedulePaths.includes(savePath),
          displayName,
        }
      })
    },

    async buildCheckOutScheduleEnrollments (_, { items }) {
      return items.map(item => {
        const frequency = CreateAutopayFrequencyFromPayableForCheckout(item.payable)
        const frequencyRule = CreateFrequencyRule(frequency)

        return {
          cartItemID: item.id,
          agreement: item?.scheduledPaymentAgreement?.terms || '',
          frequencyRule,
        }
      })
    },

    async checkForExistingSchedules ({ state, rootGetters }, payablePath) {
      if (payablePath in state.checkedSchedules) {
        return state.checkedSchedules[payablePath]
      }
      const api = rootGetters['API/schedPay']
      const res = await api.checkExistence(payablePath)
      state.checkedSchedules[payablePath] = res.data
      return res.data
    },

    setPendingAddressChange ({ state }, { payablePath, value }) {
      state.pendingAddressChanges[payablePath] = value
    },
  }

  const mutations = {
    setLoadPromise (state, loadPromise) {
      Vue.set(state, 'loadPromise', loadPromise)
    },

    setAutoloadPath (state, path) {
      Vue.set(state, 'autoloadPath', path)
    },

    clearAutoloadPath (state) {
      Vue.set(state, 'autoloadPath', null)
    },

    setSearchBy (state, value) {
      Vue.set(state, 'searchBy', value)
    },

    setSavedSearchInputs (state, value) {
      Vue.set(state, 'savedSearchInputs', value)
    },

    setClientSearch (state, value) {
      Vue.set(state, 'siteSearch', null)
      Vue.set(state, 'clientSearch', value)
    },

    setShowTermsCheckbox (state, value) {
      Vue.set(state, 'showTermsCheckbox', value)
    },

    doneLoading (state) {
      Vue.set(state, 'doneLoading', true)
    },

    setSchedule (state, schedule) {
      Vue.set(state.schedules, schedule.payable.raw.path, schedule)
    },

    removeLocalSchedule (state, path) {
      Vue.delete(state.schedules, path)
    },
  }

  const state = {
  // Maps payable paths to schedules
    schedules: {},
    // Stores the result from checking if a payable has any existing plans. Maps
    // payable path to a boolean indicating if any active plans exist for that
    // payable
    checkedSchedules: {},
    showTermsCheckbox: false,
    autoloadPath: null,
    searchBy: null,
    clientSearch: null,
    siteSearch: null,
    showDropdowns: true,
    doneLoading: false,
    savedSearchInputs: null,
    // When Renewals are displayed on the Schep dashboard, the public site will
    // send a request to the Address Changes Service to check if that renewal
    // has any pending address changes. The results will be stored in this
    // object.
    pendingAddressChanges: {
    // maps payable path to the pending address change result
    // null = no pending address change
    // undefined = address change request has not fulfilled
    // object = the pending address change request
    // payableSavePath: {
    // fields stored in this object will match what is returned by the addr
    // change api: https://bitbucket.grantstreet.com/projects/UX/repos/address-changes/browse/rest/openapi-spec.yml#163-180
    // },
    // ex:
    // '/RenewExpress/some_payable_path': {
    //     address: '339 Sixth Ave #1400',
    //     city: 'Pittsburgh',
    //     state: 'PA',
    //     zip: '15222',
    //     country: 'US',
    //   },
    },
  }

  return {
    namespaced: true,

    state,
    getters,
    mutations,
    actions,

    strict: process.env.NODE_ENV !== 'production',
  }
}
