<template>
  <component
    v-bind="$attrs"
    :is="vueComponent"
    ref="schedPay"
  />
</template>

<script>
import { sentryException } from './sentry.js'
import { createModuleLoaderMixin } from '@grantstreet/psc-vue/utils/module-loader-mixin.ts'

export default {
  compatConfig: {
    // This is necessary for child-emitted events to propagate via $attrs
    // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
    INSTANCE_LISTENERS: false,
  },
  mixins: [
    createModuleLoaderMixin({
      moduleName: 'SchedPay',
      supportedComponents: {
        'AutopayTermsModal': () =>
          import('./components/AutopayTermsModal.vue'),
        'SchedPay': () =>
          import('./components/SchedPay.vue'),
        'SchedPayModal': () =>
          import('./components/SchedPayModal.vue'),
      },
      exceptionLogger: sentryException,
      componentRefName: 'schedPay',
      proxyMethods: [
        'show',
      ],
    }),
  ],
}
</script>
