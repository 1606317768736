import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { aDayAfter } from '@grantstreet/psc-js/utils/date.js'
import Schedule from './Schedule.js'

// Frequency options are dynamically configured per site. They're used to run
// and summarize schedules.
//
// A spec is a frontend concept more related to a user story than the way rules
// are constructed.
//
// e.g.
// `onDate` As in: "run on a particular date." Which would effect the start
// date of a rule.
//  - or -
// `beforeDue` As in: "run X days before the due date." Which will
// effect the rule differently.

export class Frequency {
  constructor ({ isNull }) {
    // This can't be static because i18n might not be loaded until after this is
    // imported

    this.isNull = isNull
  }

  static key = 'schedpay.frequency_option'

  static injectTranslationOverridesGetter (getter) {
    Frequency.getTranslationOverrides = getter
  }

  clone () {
    const clone = new this.constructor(this)
    if (clone.spec) {
      clone.spec = clone.spec.clone()
    }
    return clone
  }

  getLabel () {
    return i18n.global.t(this.constructor.key + '.label')
  }
}

export class OneTimeFrequency extends Frequency {
  constructor ({ spec } = {}) {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments)
    this.spec = spec
    this.value = 'oneTime'
    this.recurring = false
  }

  static key = Frequency.key + '.one_time'

  summarize (language = 'en') {
    const summary = i18n.global.t(OneTimeFrequency.key + '.summary', {}, { locale: language })
    if (!this.spec) {
      return summary
    }
    return `${summary} ${this.spec.summarize(language)}`
  }
}

export class RecurringFrequency extends Frequency {
  constructor ({ spec } = {}) {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments)
    this.spec = spec
    this.value = 'recurring'
    this.recurring = true
  }

  static key = Frequency.key + '.recurring'

  summarize (language = 'en', shorthand = false) {
    let summary = i18n.global.t(RecurringFrequency.key + '.summary', {}, { locale: language })
    if (!this.spec) {
      return summary
    }
    if (this.spec instanceof YearlyOnDateSpec) {
      const recurrenceLabel = i18n.global.t('annually', {}, { locale: language }).toLowerCase()
      summary = `${summary} ${recurrenceLabel}`
    }
    else if (this.spec instanceof OnDateSpec) {
      const recurrenceLabel = i18n.global.t('recurrence_label', {}, { locale: language }).toLowerCase()
      if (recurrenceLabel !== 'recurrence_label') {
        summary = `${summary} ${recurrenceLabel}`
        const specialCase = `special_case.recurrence_label.${recurrenceLabel}`
        if (i18n.global.te(specialCase)) {
          summary += i18n.global.t(specialCase)
        }
      }
    }
    return shorthand
      ? this.spec.summarize(language)
      : `${summary} ${this.spec.summarize(language)}`
  }
}

class Spec {
  constructor ({ startDate = null, runNow = false } = {}) {
    this.startDate = startDate
    this.runNow = runNow
    this.offerStartDate = false
    // This is some unpleasantness required to fix a styling issue
    this.lockRadioTop = false
  }

  static key = 'schedpay.frequency_spec'

  clone () {
    return new this.constructor(this)
  }
}

export class OnDateSpec extends Spec {
  constructor () {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments)
    this.label = i18n.global.t(OnDateSpec.key + '.label')
    this.value = OnDateSpec.value
    // Start date is offered for some spec options that are not recurring.
    this.offerStartDate = true
  }

  static key = Spec.key + '.on_date'
  static value = 'onDate'

  summarize (language = 'en') {
    return i18n.global.t(OnDateSpec.key + '.summary', {}, { locale: language })
  }
}

/**
 * This is essentially a copy of the OnDateSpec above, but with a value of
 * "yearlyOnDate" instead of "onDate"
 */
export class YearlyOnDateSpec extends OnDateSpec {
  constructor () {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments)
    this.label = i18n.global.t(OnDateSpec.key + '.label')
    this.value = YearlyOnDateSpec.value
    this.offerStartDate = true
  }

  // This will share translations with the OnDateSpec
  static key = Spec.key + '.on_date'
  static value = 'yearlyOnDate'

  summarize (language = 'en') {
    return i18n.global.t(OnDateSpec.key + '.summary', {}, { locale: language })
  }
}

export class BeforeDueSpec extends Spec {
  constructor ({ daysBeforeDue } = {}) {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments)
    this.label = i18n.global.t(BeforeDueSpec.key + '.label')
    this.daysBeforeDue = daysBeforeDue
    this.value = BeforeDueSpec.value
    this.lockRadioTop = true
  }

  static key = Spec.key + '.before_due'
  static value = 'beforeDue'

  summarize (language = 'en') {
    return i18n.global.t(BeforeDueSpec.key + '.summary', {
      daysBeforeDue: this.daysBeforeDue,
    }, {
      locale: language,
    })
  }
}

/**
 * This returns an object with the frequency and amount rules
 * as needed to sign up a payable for automatic payments during the checkout
 * process. This will determine the correct frequency based on the schep config,
 * as well as determine the correct starting date for the scheduled payments, to
 * prevent double paying for the current bill.
 * (For regular autopay, this starts a day after the current due date. For
 * yearly on date payments, this starts on the limit_to_yearly_date value, one
 * year after the due date year.)
 */
export const CreateAutopayFrequencyFromPayableForCheckout = (payable) => {
  if (payable.isAutopayEligible) {
    // This is a regular autopay schedule that will pay 4 days before due
    return {
      frequency: new RecurringFrequency({
        spec: new BeforeDueSpec({
          startDate: aDayAfter(payable.dueAt),
          daysBeforeDue: 4,
        }),
      }),
      amount: {
        spec: 'amountDue',
        value: '',
      },
    }
  }
  else if (payable.isYearlyAutopayEligible) {
    // This is an autopay schedule that will be paid on a specific date every
    // year.
    const dueAtYear = payable.dueAt.getFullYear()
    const paymentStartDate = payable.schepLimitToYearlyDate
    // the current due payment will be paid during checkout. Autopay will start
    // up the following year.
    paymentStartDate.setFullYear(dueAtYear + 1)
    return {
      frequency: new RecurringFrequency({
        spec: new YearlyOnDateSpec({
          startDate: paymentStartDate,
        }),
      }),
      amount: {
        spec: 'amountDue',
        value: '',
      },
    }
  }
  return {}
}

/**
 * This returns an object representing the frequency rule needed to create
 * scheduled payment plans.
 */
export const CreateFrequencyRule = ({ frequency }) => {
  const {
    value,
    spec: {
      value: specValue,
      startDate,
      endDate,
      daysBeforeDue,
      interval,
      dayOfWeek,
    },
  } = frequency

  let dateKey = 'startDate'
  let type
  if (value === 'oneTime') {
    if (specValue === 'beforeDue') {
      type = 'OneTimeBeforeDue'
    }
    else if (specValue === 'onDate') {
      type = 'OneTimeOnDate'
      dateKey = 'date'
    }
  }
  else if (value === 'recurring') {
    if (specValue === 'beforeDue') {
      type = 'RecurringBeforeDue'
    }
    // XXX: Will need to account for different recurrence_label s later
    else if (specValue === 'onDate') {
      type = 'RecurringMonthlyOnDate'
    }
    else if (specValue === 'yearlyOnDate') {
      type = 'RecurringYearlyOnDate'
    }
    else if (specValue === 'everyXWeeks') {
      type = 'RecurringEveryXWeeks'
    }
  }
  if (!type) {
    throw new Error(`Unrecognized frequency type: ${value}`)
  }

  const data = {
    type,
    [dateKey]: Schedule.formatApiDate(startDate || new Date()),
  }

  if (daysBeforeDue) {
    data.daysBeforeDue = Number(daysBeforeDue)
  }
  if (endDate) {
    data.endDate = Schedule.formatApiDate(endDate)
  }
  if (interval) {
    data.interval = Number(interval)
  }
  if (dayOfWeek) {
    data.dayOfWeek = dayOfWeek
  }

  return data
}

/**
 * Accepts an array of payables, and groups those payables based on shared
 * terms.
 * Regular Autopay payments (made 4 days before due) will share terms.
 * Yearly On Date payments will have separate terms that indicates what day of
 * the year the plans will process on.
 *
 * A sample frequency is provided for each group of terms that will be used by
 * the AutoPayTermsModal component.
 *
 * The returned data structure will look like:
 * [
 *  {
 *    terms: (freqencyObject - see above for reference),
 *    payables: [(payable object)]
 *  },
 *  ...
 * ]
 */
export const GroupPayablesWithFrequencies = (payables) => {
  const termGroups = []

  const beforeDuePayables = []
  const yearlyOnDatePayables = []
  payables.forEach(payable => {
    const allowed = payable.scheduledPaymentsConfig.allowedTypes.recurring
    if (allowed?.beforeDue?.includes('amountDue')) {
      beforeDuePayables.push(payable)
    }
    else if (allowed?.yearlyOnDate?.includes('amountDue')) {
      yearlyOnDatePayables.push(payable)
    }
  })

  if (beforeDuePayables.length) {
    termGroups.push({
      terms: CreateAutopayFrequencyFromPayableForCheckout(beforeDuePayables[0]),
      payables: beforeDuePayables,
    })
  }

  // Group yearly payables
  const groupedYearlySchedules = {}
  yearlyOnDatePayables.forEach(payable => {
    const limitedDate = payable.scheduledPaymentsConfig.limitToYearlyDate
    if (!Object.keys(groupedYearlySchedules).includes(limitedDate)) {
      groupedYearlySchedules[limitedDate] = []
    }
    groupedYearlySchedules[limitedDate].push(payable)
  })

  Object.entries(groupedYearlySchedules).forEach(([date, groupedPayables]) => {
    termGroups.push({
      terms: CreateAutopayFrequencyFromPayableForCheckout(groupedPayables[0]),
      payables: groupedPayables,
    })
  })

  return termGroups
}

export class EveryXWeeksSpec extends Spec {
  constructor ({ interval, dayOfWeek } = {}) {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments)
    this.label = i18n.global.t(EveryXWeeksSpec.key + '.label')
    this.interval = interval
    this.value = EveryXWeeksSpec.value
    this.dayOfWeek = dayOfWeek
  }

  static key = Spec.key + '.every_x_weeks'
  static value = 'everyXWeeks'

  summarize (language = 'en') {
    return i18n.global.t(EveryXWeeksSpec.key + '.summary', {
      intervalText: i18n.global.t(`every_x_weeks_${this.interval}.label`),
      dayOfWeek: i18n.global.t('day_of_week.' + this.dayOfWeek.toLowerCase()),
    }, {
      locale: language,
    })
  }
}

export const frequencies = {
  oneTime: OneTimeFrequency,
  recurring: RecurringFrequency,
}

export const specs = {
  onDate: OnDateSpec,
  yearlyOnDate: YearlyOnDateSpec,
  beforeDue: BeforeDueSpec,
  everyXWeeks: EveryXWeeksSpec,
}
