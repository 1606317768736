<template>
  <div class="gh-page-padding pb-5">
    <LoadingBars v-if="loading" />
    <template v-else>
      <h1
        class="mt-2 mb-3 pb-2 mb-md-4 border-bottom"
        data-test="page-title"
      >
        {{ title }}
      </h1>

      <h5
        v-if="subtitle"
        class="mb-2 mb-md-3"
        data-test="page-subtitle"
      >
        {{ subtitle }}
      </h5>

      <div
        v-if="description"
        v-dompurify-html="description"
        class="mb-2 mb-md-3"
      />

      <component
        :is="searchTypeComponent"
        :payables-adaptor="payablesAdaptor"
        :search-inputs="searchInputs"
        :example-image="exampleImage"
        :example-image-text="exampleImageText"
        :example-image-alt-text="exampleImageAltText"
        :display-type="displayType"
        :blind-display-name-label="blindDisplayNameLabel"
        :blind-display-name-placeholder="blindDisplayNamePlaceholder"
        :expand-component-key="expandComponentKey"
        :search-type="searchType"
        :item-name="itemName"
        :generate-unique-id="generateUniqueId || null"
        :optional-search-params="optionalParams"
      />
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'

// Search page types (dynamically import to reduce overall GovHub bundle size)
const FieldSearchWrapper = () => import('../components/search/FieldSearchWrapper.vue')
const FieldSearchRExWrapper = () => import('../components/search/FieldSearchRExWrapper.vue')
const ItemListWrapper = () => import('../components/search/ItemListWrapper.vue')
const ExpandableListWrapper = () => import('../components/search/ExpandableListWrapper.vue')
const SelfServeWrapper = () => import('../components/search/SelfServeWrapper.vue')
const IndexSearchWrapper = defineAsyncComponent(() => import('../components/search/IndexSearchWrapper.vue'))

// XXX: Consider using async components so we load only the search type
// component we need (instead of all of them).
const allowedSearchTypes = {
  'field-search': FieldSearchWrapper,
  'field-search-renewexpress': FieldSearchRExWrapper,
  'field-search-rexhub': FieldSearchRExWrapper,
  'item-list': ItemListWrapper,
  'expandable-item-list': ExpandableListWrapper,
  'item-dropdown': SelfServeWrapper,
  'blind': SelfServeWrapper,
  'index-search': IndexSearchWrapper,
}

export default {
  components: {
    LoadingBars,
  },

  props: {
    payablesAdaptor: {
      type: String,
      required: true,
    },
    searchType: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    searchInputs: {
      type: Array,
      default: () => ([]),
    },
    exampleImage: {
      type: String,
      default: '',
    },
    exampleImageText: {
      type: Object,
      default: () => ({}),
    },
    exampleImageAltText: {
      type: Object,
      default: () => ({}),
    },
    itemName: {
      type: Object,
      required: true,
    },
    displayType: {
      type: String,
      required: true,
    },
    blindDisplayNameLabel: {
      type: Object,
      required: true,
    },
    blindDisplayNamePlaceholder: {
      type: Object,
      required: true,
    },
    expandComponentKey: {
      type: String,
      default: '',
    },
    generateUniqueId: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      loading: true,
      deliveryOption: '',
    }
  },

  computed: {
    title () {
      const title = this.$route.meta.title
      return title ? (title[this.$i18n.locale] || '') : ''
    },

    subtitle () {
      const subtitle = this.$route.meta.subtitle
      return subtitle ? (subtitle[this.$i18n.locale] || '') : ''
    },

    description () {
      const description = this.$route.meta.description
      return description ? (description[this.$i18n.locale] || '') : ''
    },

    searchTypeComponent () {
      const component = allowedSearchTypes[this.searchType]
      if (!component) {
        console.error(`PayHub Error: Unknown searchType "${this.searchType}"`)
        return null
      }
      return component
    },

    optionalParams () {
      if (this.searchType === 'field-search-renewexpress' ||
        this.searchType === 'field-search-rexhub') {
        return { deliveryOption: this.deliveryOption }
      }

      return {}
    },

    ...mapGetters('Cart', [
      'cartLoadPromise',
    ]),

    ...mapState('Cart', {
      deliveryOptionFromStore: 'deliveryOption',
    }),
  },

  async mounted () {
    try {
      await this.cartLoadPromise
      const deliveryOption = await this.getCurrentPayableDeliveryType()
      this.deliveryOption = deliveryOption

      if (this.deliveryOption === '') {
        this.$store.commit('Cart/setDeliveryOption', deliveryOption)
      }
    }
    catch (error) {
      console.error('Could not initialize cart:', error)
      this.$router.replace({
        name: 'load-failure',
        params: this.$route.params,
      })
    }
    finally {
      this.loading = false
    }
  },

  methods: {
    async getCurrentPayableDeliveryType () {
      let deliveryOption = this.deliveryOptionFromStore
      if (!deliveryOption) {
        deliveryOption = await this.$store.dispatch('Cart/getCartRenewalType')
      }
      return deliveryOption
    },
  },
}
</script>
