import { Payable, inflateDisplayType, getPayableSource } from '@grantstreet/payables'
import { parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import CartItem from '../models/CartItem.ts'

/**
 * Inflates cart data.
 * You probably shouldn't be using this. The cart store should always be doing
 * its own inflation in Cart/inflateAndUpdateCart. If you are using this
 * anywhere else double check that you should be doing whatever it is.
 */
export const inflateCartData = (cart) => {
  // I almost 100% sure that we don't need to kill this reference. The
  // method used to do it, but I'm pretty sure it was incidental
  return {
    ...cart,
    items: cart.items.map(item => inflateItem(item)) || [],
  }
}

export const inflateItem = (item, enrollInAutopay = item.enrollInAutopay) => {
  const raw = item.details
  const rawUpdatedPayable = item.updatedDetails

  const payable = new Payable({
    raw,
    configDisplayType: inflateDisplayType({ displayType: raw.display_type }),
  })

  const updatedPayable = !rawUpdatedPayable ? null : new Payable({
    raw: rawUpdatedPayable,
    configDisplayType: inflateDisplayType({ displayType: rawUpdatedPayable.display_type }),
  })

  return new CartItem(payable, {
    ...item,
    updatedPayable,
    payableNotFound: item.notFound,
    id: item.id || item.itemIdentifier,
    enrollInAutopay: Boolean(enrollInAutopay),
    userParameters: item.user_parameters || item.userParameters,
  })
}

export const inflateTransaction = (transaction, autopayMap) => {
  return {
    ...transaction,
    items: transaction.items.map(item => inflateItem(
      item,
      // Cart BE doesn't return autopay status so we need to preserve the values
      // set on the cart page by adding them into the transactions returned from
      // cart BE
      // TODO: Can we change that ☝️ in the BE?

      // This is the rare case where we want to use undefined directly. If no
      // map is set then the default will be item.enrollInAutopay (which is
      // probably false).
      autopayMap ? Boolean(autopayMap[item.id]) : undefined,
    )),
  }
}

// Returns an array of display types and the items in them, like:
//   [
//     {
//       configDisplayType: { ... },
//       // List of all payables, includes singleItems and any child items
//       items: [ ... ],
//       // List of payables excluding payables in childSiblings
//       singleItems: [ ... ]
//       // Lists of children, grouped by children sharing a parent
//       childSiblings: [
//         description: 'Yellow Cab of Sacramento',
//         displayName: 'Agreement #001111',
//         // list of payables
//         children: [ ... ],
//       ]
//     },
//     ...
//   ]
export const getItemsByDisplayType = items => {
  // We don't really need both of these but building the array saves
  // iterating to get the Object.values()
  const displayTypeMap = {}
  const itemsByDisplayType = []
  // Child payables grouped by save path. Just used to keep an easy lookup for
  // the reference, while building this out.
  const savePathMap = {}

  for (const item of items) {
    const {
      payable: { savePath, parent, configDisplayType: { name: typeName }, configDisplayType },
      payable,
    } = item

    // If there's no type in the map yet, create it
    if (!displayTypeMap[typeName]) {
      const entry = {
        configDisplayType,
        items: [],
        singleItems: [],
        childSiblings: [],
      }
      displayTypeMap[typeName] = entry
      itemsByDisplayType.push(entry)
    }
    // List the item
    displayTypeMap[typeName].items.push(item)

    // Populate childSiblings
    if (parent) {
      // Create path if necessary
      if (!savePathMap[savePath]) {
        const entry = {
          // TODO: This would be improved by PSC-8175
          description: parent.description || payable.description,
          displayName: parent.displayName || payable.displayName,
          savePath,
          children: [],
        }
        savePathMap[savePath] = entry
        displayTypeMap[typeName].childSiblings.push(entry)
      }
      savePathMap[savePath].children.push(item)
    }
    // Populate singleItems
    else {
      displayTypeMap[typeName].singleItems.push(item)
    }
  }

  return itemsByDisplayType
}

// TODO: PSC-20225 Remove this logic out from the helper and instead used locked cart.
// We only show Remove Item links on non-redirect items.
// The FE doesn't have the logic to know which redirect items must be paid
// together. (For instance, in CA tax bills, installments 1 & 2 can be paid
// together, but installment 2 cannot be paid alone. So allowing a user to
// redirect in with both and then remove installment 1 would cause problems
// for the county.)
// Bugs and overcomplicating this process have caused us major headaches and
// multiple incidents in the past. Proceed with caution.
export const showItemRemoveLink = item => {
  const payableSource = getPayableSource(item.payable.configDisplayType.name)
  // Only allow items to be removed if this is *definitely* not a redirect item.
  // We should default to no-removal if there's no source so that unknown future
  // bugs don't allow people to pay one tax installment without another or
  // something bad.
  if (payableSource && payableSource.sourceType !== 'redirect') {
    return true
  }
  return false
}

// Items with amounts exceeding 7 digits (i.e., over $1,000,000) can have
// display issues where the amount overlaps with other elements on the page.
// This just checks if said item is one of those offenders.
export const itemHasLargeAmount = item => {
  const amount = item.updatedPayable
    ? item.updatedPayable.amount
    : (item.amount || 0)
  const quantity = item.quantity || 1
  const total = parseNumber(amount) * parseNumber(quantity)

  return total > 1_000_000
}
