<template>
  <section class="text-center pb-6 px-3">
    <b-img
      :src="errorImg"
      :alt="errorText('error.' + type + '.title')"
      fluid
    />

    <div class="max-width">
      <h1 class="mt-4 mb-3">{{ errorText(`error.${type}.title`) }}</h1>

      <p>{{ errorText(`error.${type}.desc`) }}</p>

      <p
        v-if="!hideSupportLink"
        v-dompurify-html="errorText(`error.support`)"
      />

      <!-- Hide the "click the button below" guide if we are hiding the button -->
      <p
        v-if="showButton"
        v-dompurify-html="errorText(`error.${type}.guide`)"
      />
    </div>

    <template v-if="showButton">
      <HomepageLink
        v-if="returnHome"
        class="mt-3"
        variant="primary"
        data-test="return-link"
        definitely-go-home
      >
        {{ errorText(`error.${type}.return`) || $t('error.return') }}
      </HomepageLink>
      <CancelCartButton
        v-else
        fallback-homepage
        class="mt-3"
        variant="primary"
        data-test="return-link"
      />
    </template>
  </section>
</template>

<script>
import { displayFormat } from '@grantstreet/psc-js/utils/numbers.js'
import errorImgSrc from '../assets/error-page-illustration.svg'
import ccImgSrc from '../assets/cc-icon-large.svg'
import { mapGetters } from 'vuex'
import HomepageLink from './HomepageLink.vue'
import CancelCartButton from './CancelCartButton.vue'
import { mapConfigGetters } from '@grantstreet/psc-config'

export default {
  components: {
    HomepageLink,
    CancelCartButton,
  },

  props: {
    type: {
      type: String,
      default: 'notFound',
      validator: val => {
        return [
          'notFound',
          'limitExceeded',
          'cartError',
          'networkError',
          'loginError',
        ].includes(val)
      },
    },
    hideSupportLink: {
      type: Boolean,
      default: false,
    },
    isRedirect: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    errorTitle () {
      return this.$t(`error.${this.type}.title`)
    },

    errorImg () {
      switch (this.type) {
      case 'limitExceeded':
        return ccImgSrc
      default:
        return errorImgSrc
      }
    },

    returnHome () {
      // We want to redirect to the landing page if we don't have a return url
      // from a redirect
      return !this.urls?.return && this.siteUsesHomepage
    },

    ...mapGetters('PayHub', [
      'getPaymentLimits',
      'clientTitle',
    ]),

    ...mapGetters('Cart', [
      'urls',
    ]),

    ...mapConfigGetters(['siteUsesHomepage']),
  },

  methods: {
    errorText (i18nKey) {
      if (!this.$te(i18nKey)) {
        return ''
      }

      // TODO: PSC-13498 Account for bank limits and alternate tender sources
      // Get the old non-multi-department payment limit. Once we do the work
      // to support multi-department redirects (PSC-3858) we can get rid of
      // the LimitExceeded page entirely.
      const cardLimit = this.getPaymentLimits()?.cardLimit

      return this.$t(i18nKey, {
        // This needs to include all the interpolation values that any
        // of the `errorText` messages use. If it gets out of hand, we
        // might want to look them up on demand based on the key.
        departmentFullName: this.clientTitle,
        cardLimit: displayFormat(cardLimit, true),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 2rem;

  @include media-breakpoint-up(sm) {
    font-size: 3rem !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 5rem !important;
  }
}

.max-width {
  margin: auto;
  max-width: 40rem;
}

.btn {
  // Wrap button text
  white-space: normal;
}
</style>
